import React from 'react'
import LabelContent from '../LabelContent'
import useStyles from './useStyles'

function LabelInfo({ labelKey, label, info }) {
    const classes = useStyles()

    return (
        <LabelContent label={label}>
            <span key={labelKey} className={classes.labelinfo_info}>{info}</span>
        </LabelContent>
    )
}

export default LabelInfo
