import { green, red } from '@material-ui/core/colors'

const { makeStyles } = require('@material-ui/styles')

const newsletterspan = {
    fontWeight: 700
}

const useStyles = makeStyles({
    content: {
        marginTop: 30,
        '& > div': {
            marginTop: 10
        }
    },
    newsletterspanenabled: {
        ...newsletterspan,
        color: green[600]
    },
    newsletterspandisabled: {
        ...newsletterspan,
        color: red[600]
    },
    newsletterdescription: {
        fontSize: '0.8rem',
        marginTop: 5
    }
})

export default useStyles
