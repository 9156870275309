import React from 'react'
import { Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import useStyles from './useStyles'

function Footer() {
    const { t } = useTranslation()
    const classes = useStyles()
    const urlImgIcon = `${process.env.PUBLIC_URL}/assets/imgs/icon`

    if (!window?.electron) {
        return (
            <>
                <footer className={classes.footer}>
                    <div className={classes.divider_footer}></div>
                    <section key="footer" className={classes.footer_container}>
                        {/* <div className={classes.footer_box}>
                            <NavLink to="/" className={classes.footer_link}>
                                {t(`${translationkey}.mainpage`)}
                            </NavLink>
                            <NavLink to="/" className={classes.footer_link}>
                                {t(`${translationkey}.forum`)}
                            </NavLink>
                            <NavLink to="/" className={classes.footer_link}>
                                {t(`${translationkey}.become`)}
                            </NavLink>
                            <NavLink to="/" className={classes.footer_link}>
                                {t(`${translationkey}.faq`)}
                            </NavLink>
                            <NavLink to="/" className={classes.footer_link}>
                                {t(`${translationkey}.needhelp`)}?
                            </NavLink>
                        </div> */}
                        <div className={classes.footer_box}>
                            <Link
                                href="https://www.facebook.com/profanemmo"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/facebook-icon.webp`} alt="Facebook" />
                            </Link>
                            <Link
                                href="https://twitter.com/profanemmo"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/twitter-icon.webp`} alt="Twitter" />
                            </Link>
                            <Link
                                href="https://www.instagram.com/profanemmo"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/instagram-icon.webp`} alt="Instagram" />
                            </Link>
                            <Link
                                href="https://discord.gg/profanemmo"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/discord-icon.webp`} alt="Discord" />
                            </Link>
                            <Link
                                href="https://www.reddit.com/r/profanemmo/"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/reddit-icon.webp`} alt="Reddit" />
                            </Link>
                            <Link
                                href="https://www.youtube.com/c/profanemmo"
                                target="_blank"
                                className={classes.footer_link_social}
                            >
                                <img className={classes.footer_link_social_icon} src={`${urlImgIcon}/youtube-icon.webp`} alt="YouTube" />
                            </Link>
                        </div>
                        <div className={classes.footer_box}>
                            <Link href="https://www.insa.ne/" target="_blank">
                                <img
                                    className={classes.footer_img}
                                    src={`${process.env.PUBLIC_URL}/assets/imgs/insane.webp`}
                                    alt="Insane"
                                />
                            </Link>
                        </div>
                        <div className={classes.footer_box}>
                            <p className={classes.footer_privacy}>
                                <br />© 2024 {t('footer.privacy.rights')}
                            </p>
                        </div>

                        <div className={classes.footer_box}>
                            <Link className={classes.footer_link_menu} href={t('privacy.policy.link')} target="_blank" rel="noopener noreferrer">
                                {t('privacy.policy.label')}
                            </Link >
                            <Link className={classes.footer_link_menu} href={t('privacy.terms.link')} target="_blank" rel="noopener noreferrer">
                                {t('privacy.terms.label')}
                            </Link >
                        </div>
                    </section>
                </footer>
            </>
        )
    }
    return (null)
}

export default Footer
