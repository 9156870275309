import { colors } from '../../../global/theme'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    input: {
        width: '100%',
        marginBottom: 10,

        '&> div': {
            background: '#21282b',
            padding: 10,

            '&> input': {
                fontSize: 12,
                fontWeight: 100
            },

            '&:after': {
                content: 'none'
            },
            '&:before': {
                content: 'none'
            }
        }
    },
    checkbox: {
        marginLeft: -3,
        marginRight: 0,
        marginBottom: 10,

        '&> label': {
            marginLeft: 0,
            marginRight: 5,

            '&> span': {
                padding: 0,
                color: colors.typography.label,

                '&> span': {
                    color: colors.typography.label
                }
            }
        },

        '&> span': {
            fontSize: 13,
            fontFamily: 'D-DIN Regular',
            color: colors.typography.label
        }
    },
    signin_section: {

    },
    signin_link: {
        textDecoration: 'none',
        marginBottom: '13px',
        '&> a': {
            fontFamily: 'D-DIN Regular',
            color: colors.typography.label,
            fontSize: 13
        },
        '&> a:hover': {
            textDecoration: 'none'
        },
        '&> a > span': {
            marginLeft: '0.5em',
            color: colors.typography.link
        }
    },
    signup_section: {
        marginTop: '2em',
        textAlign: 'center'
    },
    signup_label: {
        fontFamily: 'D-DIN Regular',
        color: colors.typography.label,
        fontSize: 15
    },
    signup_link: {
        textDecoration: 'none',
        '&> a': {
            color: colors.typography.label
        },
        '&> a:hover': {
            textDecoration: 'none'
        },
        '&> a > span': {
            marginLeft: '0.5em',
            color: colors.typography.link
        }
    },
    policy_section: {
        marginTop: '1em',
        textAlign: 'center'
    },
    policy_link: {
        fontFamily: 'D-DIN DIN-Bold',
        fontSize: 15,
        textDecoration: 'none',
        '&> a': {
            color: colors.typography.label
        },
        '&> a:hover': {
            textDecoration: 'none'
        },
        '&> a > span': {
            marginLeft: '0.5em',
            color: colors.typography.link
        }
    }
})

export default useStyles
