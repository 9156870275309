import { grey } from '@material-ui/core/colors'

const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    appbar: {
        backgroundColor: grey[900]
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    img: {
        width: 145
    },
    menus: {
        display: 'flex',
        alignItems: 'center'
    }
})

export default useStyles
