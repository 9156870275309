import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import AuthService from './service'

export const AuthContext = createContext()

export function AuthProvider({ children }) {
    const auth = useProvideAuth()
    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

function useProvideAuth() {
    const [user, setUser] = useState(null)
    const [loadingAuthState, setLoadingAuthState] = useState(true)

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
            if (!firebaseUser) {
                setLoadingAuthState(false)
            }
            try {
                const user = await AuthService.getUser(firebaseUser)
                setUser(user)

                setLoadingAuthState(false)
                window.currentUser = user.token
                console.debug('[auth] state changed', window.currentUser)
                window.dispatchEvent(new CustomEvent('user/changed', { detail: { user: user.token } }))
            } catch (e) {
                console.error('onAuthStateChanged', e)
                setLoadingAuthState(false)
            }
        })
        // Cleanup subscription on unmount
        return () => unsubscribe()
    }, [])

    const signup = async props => {
        await AuthService.signout()
        setUser(null)
        return AuthService.signup(props)
    }

    const signout = async () => {
        await AuthService.signout()
        setUser(null)
    }

    return {
        loadingAuthState,
        user,
        authenticated: user !== null,
        currentUser: firebase.auth().currentUser,
        signin: AuthService.signin,
        signout: signout,
        signup: signup,
        sendResetPasswordCode: AuthService.sendResetPasswordCode,
        verifyCode: AuthService.verifyCode,
        confirmNewPassword: AuthService.confirmNewPassword
    }
}
