import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import SignIn from '../components/pages/SignIn'
import SignUp from '../components/pages/SignUp'
import ResetPassword from '../components/pages/ResetPassword'
import PrivateRoute from './PrivateRoute'
import AuthRoute from './AuthRoute'
import AccountOverview from '../components/pages/AccountOverview'
import DiscordCallback from '../components/pages/DiscordCallback'
import { withTranslation } from 'react-i18next'

function AppRoute() {
    return (
        <Switch>
            <AuthRoute component={SignIn} path="/signin" exact />
            <AuthRoute component={SignUp} path="/signup" exact />
            <AuthRoute component={ResetPassword} path="/resetpassword" exact />
            <PrivateRoute component={AccountOverview} path="/accountoverview" exact />
            <PrivateRoute component={DiscordCallback} path="/discordcallback" exact />
            <Redirect to="/signin" />
        </Switch>
    )
}

export default withTranslation()(AppRoute)
