import React, { useState } from 'react'
import { useAuth } from '../../../auth/useAuth'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import useStyles from './useStyles'

function MenuUser() {
    const { user, signout } = useAuth()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()

    const onHandleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const onHandleCloseUserMenu = () => {
        setAnchorEl(null)
    }

    const onHandleLogout = () => {
        signout()
    }

    return (
        <div>
            <Button
                className={classes.button}
                startIcon={<AccountCircleOutlinedIcon fontSize="large" />}
                onClick={onHandleOpenUserMenu}
            >
                {user ? user.displayName : ''}
            </Button>
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                id="menu-language"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onHandleCloseUserMenu}
            >
                <MenuItem>
                    <NavLink to="/accountoverview" className={classes.link}>
                        <span className={classes.textmenu}>{t('user.account')}</span>
                    </NavLink>
                </MenuItem>
                <MenuItem>
                    <NavLink to="/signin" className={classes.link}>
                        <span className={classes.textmenu} onClick={onHandleLogout}>
                            {t('user.singout')}
                        </span>
                    </NavLink>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default MenuUser
