import { grey } from '@material-ui/core/colors'
import { form } from '../../../global/form'
import { colors } from '../../../global/theme'
import { makeStyles } from '@material-ui/styles'

const typography = {
    color: colors.typography.label,
    textTransform: 'uppercase',
    fontSize: 10,
    marginBottom: 3,
    fontWeight: 700
}

const useStyles = makeStyles({
    cardStyle: {
        backgroundColor: colors.cards.background,
        maxWidth: 450,
        borderRadius: 0,
        minWidth: 260,
        padding: '20px 40px 0'
    },
    cardContent: {
        width: '100%',
        paddingTop: '0'
    },
    input: form.input,
    checkbox: form.checkbox,
    typography: typography,
    typography_margintop: {
        ...typography,
        marginTop: 15
    },
    typography_margin: {
        ...typography,
        marginTop: 15,
        marginBottom: 15,
        textAlign: 'center'
    },
    link: {
        fontSize: 12,
        display: 'block',
        marginBottom: 10,
        textDecoration: 'none',

        '&> a': {
            color: grey[500]
        }
    },
    signup_link: {
        textDecoration: 'none',
        '&> a': {
            color: colors.typography.label
        },
        '&> a:hover': {
            textDecoration: 'none'
        },
        '&> a > span': {
            marginLeft: '0.5em',
            color: colors.typography.link
        }
    },
    select: {
        ...form.input,
        '& > div': {
            color: grey[500]
        },
        '& .MuiSelect-icon': {
            color: grey[500]
        }
    },
    signup_section: {
        marginTop: '1em',
        textAlign: 'center'
    },
    signup_label: {
        fontFamily: 'D-DIN Regular',
        color: colors.typography.label,
        fontSize: 15
    },
    policy_section: {
        marginTop: '1em',
        textAlign: 'center'
    },
    policy_link: {
        fontFamily: 'D-DIN DIN-Bold',
        fontSize: 15,
        textDecoration: 'none',
        '&> a': {
            color: colors.typography.label
        },
        '&> a:hover': {
            textDecoration: 'none'
        },
        '&> a > span': {
            marginLeft: '0.5em',
            color: colors.typography.link
        }
    },
    signup_header: {
        ...typography,
        fontSize: 15,
        textAlign: 'center'
    }
})

export default useStyles
