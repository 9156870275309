import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SignInCard from '../../Orgs/SignInCard'
import BackgroundImg from '../../Molecules/BackgroundImg'

import './style.scss'

const SignIn = ({ history }) => {
    useTranslation()

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" id="login">
            <BackgroundImg />
            <SignInCard history={history} />
        </Grid>
    )
}

export default SignIn
