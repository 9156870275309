const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    content: {
        marginTop: 5,
        '& > div': {
            marginTop: 10
        },
        width: '50%',
        height: '40%',
        display: 'flex',
        justifyContent: 'center'
    },
    wrapper: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    label: {
        display: 'block',
        width: '100%',
        minWidth: '30%',
        fontWeight: 100,
        fontSize: '0.8rem',
        marginRight: 10,
        height: '30%',
        textAlign: 'center',
        marginBottom: 0
    },
    discord_btn: {
        background: '#5865f2',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '10px',
        width: '130px',
        fontFamily: '"gg sans","Noto Sans","Helvetica Neue",Helvetica,Arial,sans-serif',
        fontWeight: '500',
        color: 'white'
    },
    discord_btn_drescription: {
    },
    head_boxview_subtitle: {
        fontSize: '0.8rem',
        display: 'flex',
        justifyContent: 'center'
    }
})

export default useStyles
