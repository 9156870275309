import React from 'react'
import {
    FormControlLabel,
    LinearProgress,
    Typography,
    Link as MaterialLink
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'

import { Trans, useTranslation } from 'react-i18next'
import CustomButton from '../../Atoms/CustomButton'

import { Link } from 'react-router-dom'
import CardLogin from '../../Molecules/CardLogin'

import { useAuth } from '../../../auth/useAuth'
import useStyles from './useStyles'

function SignInCard({ history }) {
    const { signin } = useAuth()
    const { t } = useTranslation()
    const classes = useStyles()

    const validate = (values) => {
        const errors = {}
        const required = 'validation.required'

        if (!values.email) {
            errors.email = t(required)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('validation.email')
        }

        if (!values.password) {
            errors.password = t(required)
        }

        return errors
    }

    const setSigninError = (error, setFieldError) => {
        if (error.code === 'auth/wrong-password') {
            setFieldError('password', t('validation.invalid_password'))
        }
        if (error.code === 'auth/user-not-found') {
            setFieldError('email', t('validation.email_not_registered'))
        }
    }

    const onSubmit = async (values, { setSubmitting, setFieldError }) => {
        try {
            await signin(values.email, values.password)
            history.push('/accountoverview')
        } catch (error) {
            setSigninError(error, setFieldError)
            console.log('error', error)
        }
        setSubmitting(false)
    }

    return (
        <CardLogin>
            <section key="signin_section" className={classes.signin_section}>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        remember: false
                    }}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    {({ submitForm, isSubmitting, dirty }) => (
                        <Form>
                            <Field
                                component={TextField}
                                name="email"
                                type="email"
                                className={classes.input}
                                placeholder={t('login.email')}
                            />
                            <br />
                            <Field
                                component={TextField}
                                type="password"
                                name="password"
                                className={classes.input}
                                placeholder={t('login.password')}
                            />
                            <div className="login__card__div">
                                <FormControlLabel
                                    label={t('login.remember')}
                                    className={classes.checkbox}
                                    control={<Field component={CheckboxWithLabel} name="remember" type="checkbox" />}
                                />
                                <Link className={classes.signin_link} to="/resetpassword">
                                    <MaterialLink>
                                        <Trans i18nKey="login.forgot">Forgot password</Trans>
                                    </MaterialLink>
                                </Link>
                            </div>
                            {isSubmitting && <LinearProgress />}
                            <br />
                            <CustomButton
                                disabled={isSubmitting || !dirty}
                                onClick={submitForm}
                                color="primary"
                                variant="contained"
                                fullWidth
                                size="large"
                            >
                                {t('login.signin')}
                            </CustomButton>
                        </Form>
                    )}
                </Formik>
            </section>
            <section key="signup_label" className={classes.signup_section}>
                <Typography className={classes.signup_label} variant="body1">
                    <Trans i18nKey="login.donthaveanaccount">Don’t have a Profane account</Trans>?
                    <Link className={classes.signup_link} to="/signup">
                        <MaterialLink>
                            <Typography className={classes.signup_label} variant="body1">
                                <Trans i18nKey="login.signup">Sign up</Trans>
                            </Typography>
                        </MaterialLink>
                    </Link>
                </Typography>

            </section>

            <section key="privacypolicy" className={classes.policy_section}>
                <a className={classes.policy_link}>
                    <MaterialLink href={t('privacy.policy.link')} target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="privacy.policy.label">Privacy Policy</Trans>
                    </MaterialLink>
                </a>
            </section>
        </CardLogin>
    )
}

export default SignInCard
