const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    textmenu: {
        display: 'block',
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 10,
        width: '100%',
        textAlign: 'center'
    }
})

export default useStyles
