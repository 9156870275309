import React from 'react'
import ContainerView from '../../Molecules/ContainerView'
import { useTranslation } from 'react-i18next'
import BoxView from '../../Molecules/BoxView'
import YourInformation from '../../Orgs/YourInformation'
import DiscordLink from '../../Orgs/DiscordLink'

function AccountOverview() {
    const { t } = useTranslation()
    const transalationKey = 'accountoverview'

    return (
        <ContainerView title={t(`${transalationKey}.title`)}>
            <BoxView
                title={t(`${transalationKey}.yourinfo.title`)}
                linkpath="/accountoverview"
                linklabel={t(`${transalationKey}.yourinfo.link`)}
            >
                <YourInformation />
            </BoxView>
            <DiscordLink />
        </ContainerView>
    )
}

export default AccountOverview
