import React from 'react'
import ApolloClient from './client'
import { useAuth } from '../auth/useAuth'
import { ApolloProvider } from '@apollo/client'

export const ApolloContext = ({ children }) => {
    const { user } = useAuth()

    return (
        <ApolloProvider client={ApolloClient({ name: 'authenticated', token: user?.tokenEncrypted })}>
            {children}
        </ApolloProvider>
    )
}
