import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import useStyles from './useStyles'

function ContainerView({ title, children }) {
    const classes = useStyles()

    return (
        <section key="containerview" className={classes.container_view}>
            <Typography className={classes.typography} variant="h2">
                {title}
            </Typography>
            <Grid container spacing={3}>
                {children}
            </Grid>
        </section>
    )
}

export default ContainerView
