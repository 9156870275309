import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import BackgroundImg from '../../Molecules/BackgroundImg'
import SignUpCard from '../../Orgs/SignUpCard'

import './style.scss'

function SignUp({ history }) {
    useTranslation()

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" id="signup">
            <BackgroundImg />
            <SignUpCard history={history} />
        </Grid>
    )
}

export default SignUp
