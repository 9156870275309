import React from 'react'
import PersonIcon from '@material-ui/icons/Person'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

const menuItens = [
    {
        icon: <PersonIcon />,
        title: 'menu.accountoverview',
        path: '/accountoverview'
    },
    {
        icon: <VpnKeyIcon />,
        title: 'menu.passwordsecurity',
        path: '/passwordsecurity'
    }
]

export default menuItens
