import React, { useState } from 'react'
import LanguageIcon from '@material-ui/icons/Language'
import countries from '../../../utils/countries'
import { useTranslation } from 'react-i18next'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { LanguageEnum } from '../../../enum/LanguageEnum'
import useStyles from './useStyles'

function MenuLanguage() {
    const { t, i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()

    const onHandleOpenLanguageMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const onHandleCloseLanguageMenu = () => {
        setAnchorEl(null)
    }

    const onHandleChangeLanguage = (country) => {
        i18n.changeLanguage(LanguageEnum[country])
        setAnchorEl(null)
    }

    const renderIcon = () => (
        <IconButton aria-label="upload picture" component="span" onClick={onHandleOpenLanguageMenu}>
            <LanguageIcon />
        </IconButton>
    )

    const renderMenu = () => {
        return (
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                id="menu-language"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onHandleCloseLanguageMenu}
            >
                {countries.map((country) => (
                    <MenuItem onClick={() => onHandleChangeLanguage(country)} key={country}>
                        <span className={classes.textmenu}>{t(`language.${country}`)}</span>
                    </MenuItem>
                ))}
            </Menu>
        )
    }

    return (
        <div>
            {renderIcon()}
            {renderMenu()}
        </div>
    )
}

export default MenuLanguage
