const env = {
    HASURA: {
        URL: 'https://profane-dev-api.herokuapp.com/v1/graphql',
        API: 'https://profane-dev-api.herokuapp.com/api'
    },
    DISCORD: {
        URL: 'https://discord.com/api',
        CLIENT_ID: '1184501155603157042',
        CALLBACK_URL: 'https://account.profane.dev/DiscordCallback',
        // CALLBACK_URL: 'http://localhost:3000/DiscordCallback',
        SCOPE: 'identify+email+guilds+guilds.members.read'
    }
}
export default env
