import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles({
    menu: {
        margin: '10px 0',
        marginRight: 24,
        borderBottom: `1px solid ${grey[300]}`
    }
})

export default useStyles
