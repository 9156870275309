import { createTheme } from '@material-ui/core/styles'

const colorNames = {
    darkGrayBlue: '#171c1e',
    grayBlue: '#21282b',
    lightGrayBlue: '#a6b9c0',
    highOrange: '#ff7800',
    orange: '#FF8F00',
    white: '#fff'
}

export const colors = {
    typography: {
        label: colorNames.lightGrayBlue,
        link: colorNames.highOrange
    },
    cards: {
        background: colorNames.darkGrayBlue,
        text: colorNames.lightGrayBlue
    },
    buttons: {
        background: colorNames.highOrange,
        backgroundWhenHover: colorNames.orange,
        backgroundWhenDisabled: colorNames.grayBlue,
        text: colorNames.white
    }
}

const theme = createTheme({
    shadows: ['none'],
    palette: {
        type: 'dark'
    },
    props: {
        MuiTypography: {
            variantMapping: {
                body1: 'span',
                body2: 'span'
            }

        }
    }
})

export default theme
