import React, { Suspense, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider } from './auth/useAuth'
import AppRoute from './route/AppRoute'
import { ApolloContext } from './apollo/context'

import './App.css'

function App() {
    useEffect(() => {
        const insideIframe = window !== window.parent && window.parent
        const onUserChanged = event => {
            console.debug('User changed', event.detail)
            // window.parent.postMessage({ type: 'user/changed', payload: event.detail }, 'file://')
            window.parent.postMessage({ type: 'user/changed', payload: event.detail }, '*')
        }

        if (insideIframe) {
            window.addEventListener('user/changed', onUserChanged)
        }

        return () => {
            window.removeEventListener('user/changed', onUserChanged)
        }
    }, [])

    return (
        <AuthProvider>
            <ApolloContext>
                <Router>
                    <Suspense fallback={null}>
                        {/* <Container className={classes.container}> */}
                        <AppRoute />
                        {/* </Container> */}
                    </Suspense>
                </Router>
            </ApolloContext>
        </AuthProvider>
    )
}

export default App
