const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    labelinfo_info: {
        display: 'block',
        fontWeight: 500,
        fontSize: '1rem',
        wordBreak: 'break-all'
    }
})

export default useStyles
