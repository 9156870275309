import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    container_view: {
        // marginTop: 10,
        backgroundColor: '#fff',
        padding: 20,
        // marginLeft: 24,
        paddingBottom: 140
    },
    typography: {
        textTransform: 'uppercase',
        fontSize: '1.5rem',
        fontWeight: 800,
        marginBottom: 10
    }
})

export default useStyles
