import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import LoadingIcon from '../../../components/Atoms/LoadingIcon'

function DiscordCallback() {
    const history = useHistory()

    useEffect(() => {
        const params = window.location.search
        // history.push({
        //     pathname: '/accountoverview',
        //     search: params
        // })
        if (window.opener) {
            window.opener.postMessage(params)
            window.close()
        } else {
            console.error('No opener')
        }
    }, [])

    return <LoadingIcon/>
}

export default DiscordCallback
