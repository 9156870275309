import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    container_boxview: {
        border: `1px solid ${grey[300]}`,
        borderRadius: 3,
        boxSizing: 'border-box',
        padding: 20,
        height: '100%'
    },
    head_boxview: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    head_boxview_link: {
        color: grey[400],
        textDecoration: 'none',
        fontWeight: 600,
        fontSize: 10,
        '&> span': {
            '&> span': {
                marginLeft: -3
            }
        }
    },
    head_boxview_title: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '0.9rem',
        fontWeight: 500
    },
    head_icon_wrapper: {
        display: 'flex',
        alignItems: 'center'
    }
})

export default useStyles
