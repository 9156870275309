import { grey } from '@material-ui/core/colors'

const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    textmenu: {
        display: 'block',
        fontSize: 10,
        width: '100%',
        textAlign: 'center'
    },
    link: {
        color: grey[100],
        textDecoration: 'none'
    }
})

export default useStyles
