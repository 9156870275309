import React, { useState } from 'react'
import {
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@material-ui/icons/Language'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import countries from '../../../utils/countries'

import useStyles from './useStyles'
import { LanguageEnum } from '../../../enum/LanguageEnum'
import menuItens from '../Sidebar/menuItens'
import { NavLink } from 'react-router-dom'

function MenuXsView() {
    const { t, i18n } = useTranslation()
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openLanguage, setOpenLanguage] = useState(false)
    const [openUser, setOpenUser] = useState(false)
    const classes = useStyles()

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setOpenDrawer(!openDrawer)
    }

    const onHandleToggleLanguage = () => {
        setOpenLanguage(!openLanguage)
        setOpenUser(false)
    }

    const onHandleToggleUser = () => {
        setOpenUser(!openUser)
        setOpenLanguage(false)
    }

    const onHandleChangeLanguage = (country) => {
        i18n.changeLanguage(LanguageEnum[country])
    }

    const renderListLanguage = () => {
        return countries.map((country) => (
            <ListItem
                onClick={() => onHandleChangeLanguage(country)}
                key={country}
                ListItem
                button
                className={classes.nested}
            >
                <ListItemText primary={t(`language.${country}`)} />
            </ListItem>
        ))
    }

    const renderSideBarMenu = () => {
        return menuItens.map((menuitem) => (
            <ListItem key={menuitem.path} button>
                <ListItemIcon>{menuitem.icon}</ListItemIcon>
                <NavLink to={menuitem.path} className={classes.link}>
                    <ListItemText primary={t(menuitem.title)} />
                </NavLink>
            </ListItem>
        ))
    }

    return (
        <>
            <IconButton aria-label="menu" onClick={toggleDrawer}>
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <List className={classes.root}>
                    <ListItem button onClick={onHandleToggleUser}>
                        <ListItemIcon>
                            <AccountCircleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('user.account')} />
                        {openUser ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openUser} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <NavLink to="/accountoverview" className={classes.linkPadding}>
                                    <ListItemText primary={t('user.accountoverview')} />
                                </NavLink>
                            </ListItem>
                            <ListItem>
                                <NavLink to="/signin" className={classes.linkPadding}>
                                    <ListItemText primary={t('user.singout')} />
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button onClick={onHandleToggleLanguage}>
                        <ListItemIcon>
                            <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('common.language')} />
                        {openLanguage ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openLanguage} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {renderListLanguage()}
                        </List>
                    </Collapse>
                </List>
                <Divider />
                <List className={classes.root}>{renderSideBarMenu()}</List>
            </SwipeableDrawer>
        </>
    )
}

export default MenuXsView
