import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'

const link = {
    color: grey[100],
    textDecoration: 'none'
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    link: {
        ...link
    },
    linkPadding: {
        ...link,
        paddingLeft: theme.spacing(2)
    }
}))

export default useStyles
