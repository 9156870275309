import React from 'react'
import { Container, Grid, Hidden } from '@material-ui/core'

import Sidebar from '../../Molecules/Sidebar'
import Footer from '../../Orgs/Footer'
import Header from '../../Orgs/Header'
import useStyles from './useStyles'

function Layout({ children, history }) {
    const classes = useStyles()

    return (
        <>
            <Header history={history}></Header>
            <Container maxWidth="lg" className={classes.container}>
                {/* <CustomCarousel></CustomCarousel> */}
                <Hidden xsDown>
                    <Container className={classes.containerMargin}>
                        <Grid container>
                            <Grid item lg={3} md={3} sm={3}>
                                <Sidebar></Sidebar>
                            </Grid>
                            <Grid item xs>
                                {/* <Container>{children}</Container> */}
                                {children}
                            </Grid>
                        </Grid>
                    </Container>
                </Hidden>
                <Hidden smUp>
                    <Grid container>
                        <Grid item xs>
                            {children}
                        </Grid>
                    </Grid>
                </Hidden>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default Layout
