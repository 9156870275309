import { setContext } from '@apollo/client/link/context'
import env from '../env'
import {
    ApolloClient as AC,
    InMemoryCache,
    createHttpLink
} from '@apollo/client'

const ApolloClient = ({ token }) => {
    const httpLink = createHttpLink({
        uri: env.HASURA.URL
    })

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            }
        }
    })

    const client = new AC({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    })

    return client
}

export default ApolloClient
