import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './useStyles'
import { NavLink } from 'react-router-dom'

function SidebarItem({ title, icon, path, active, onActive }) {
    const classes = useStyles({ active })

    return (
        <NavLink to={path} className={classes.link}>
            <Button startIcon={icon} className={classes.btn} size="small" fullWidth onClick={onActive}>
                {title}
            </Button>
        </NavLink>
    )
}

export default SidebarItem
