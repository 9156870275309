import { makeStyles } from '@material-ui/styles'
import { colors } from '../../../global/theme'

const useStyles = makeStyles({
    buttonStyle: {
        backgroundColor: colors.buttons.background,
        color: colors.buttons.text,
        fontSize: 17,
        fontFamily: 'Profane',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: colors.buttons.backgroundWhenHover
        },
        '&:disabled': {
            backgroundColor: colors.buttons.backgroundWhenDisabled
        }
    }
})

export default useStyles
