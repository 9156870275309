// TODO: tentar matar esse global e deixar tudo no theme

const input = {
    width: '100%',
    marginBottom: 10,

    '&> div': {
        background: '#21282b',
        padding: 10,

        '&> input': {
            fontSize: 12,
            fontWeight: 100
        },

        '&:after': {
            content: 'none'
        },
        '&:before': {
            content: 'none'
        }
    }
}

const checkbox = {
    marginLeft: -3,
    marginRight: 0,
    marginBottom: 10,

    '&> label': {
        marginLeft: 0,
        marginRight: 5,

        '&> span': {
            padding: 0,
            color: '#21282b',

            '&> span': {
                color: '#21282b'
            }
        }
    },

    '&> span': {
        color: '#a6b9c0',
        fontSize: 13,
        fontFamily: 'D-DIN Regular'
    }
}

export const form = {
    input,
    checkbox
}
