import React from 'react'
import './styles.scss'

function LoadingIcon() {
    return <div className='loading_icon_wrapper'>
        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo.webp`} className="loading_icon" />
    </div>
}

export default LoadingIcon
