import { EventEmitter } from 'events'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { gql } from '@apollo/client'
import ApolloClient from '../apollo/client'

const config = {
    apiKey: 'AIzaSyCXTbrBhsLEzClUjd21GAWD8KIRPH6KZ28',
    authDomain: 'profane-useraccount.firebaseapp.com',
    databaseURL: 'https://profane-useraccount-default-rtdb.firebaseio.com',
    projectId: 'profane-useraccount',
    storageBucket: 'profane-useraccount.appspot.com',
    messagingSenderId: '178869480110',
    appId: '1:178869480110:web:a7fb2f52392bd7f0b5db3f',
    measurementId: 'G-KEW6FYWTGZ'
}

firebase.initializeApp(config)

const ADD_USERDETAILS = gql`
mutation MyMutation($createdAt: timestamptz, $firstName: String, $lastName: String, $displayName: String, $receiveNewsletter: Boolean = false) {
    insert_userDetails(objects: {receiveNewsletter: $receiveNewsletter, lastName: $lastName, firstName: $firstName, displayName: $displayName, createdAt: $createdAt}) {
      affected_rows
    }
  }
`

const addDetails = (rest) => ({
    mutation: ADD_USERDETAILS,
    variables: {
        createdAt: new Date().toUTCString(),
        firstName: rest.firstname,
        lastName: rest.lastname,
        receiveNewsletter: rest.news,
        displayName: rest.displayname
    }
})

const Factory = (function () {
    let instance
    return {
        getInstance: function () {
            if (instance == null) {
                instance = new AuthService()
                // Hide the constructor so the returned object can't be new'd...
                instance.constructor = null
            }
            return instance
        }
    }
})()

function AuthService() {
    const emitter = new EventEmitter()

    const signin = (email, password) => {
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((response) => {
                return getUser(response.user)
            })
    }

    const sendResetPasswordCode = (email) => {
        return firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                return true
            })
    }

    const verifyCode = (email) => {
        return firebase
            .auth()
            .verifyPasswordResetCode(email)
    }

    const confirmNewPassword = (code, newPassword) => {
        return firebase
            .auth()
            .confirmPasswordReset(code, newPassword)
            .then(() => {
                return true
            })
    }

    const signup = ({ email, password, ...rest }) => {
        return firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(response => response.user.sendEmailVerification().then(() => Promise.resolve(response.user)))
            .then(user => user.updateProfile({ displayName: rest.displayname }).then(() => Promise.resolve(user)))
            .then(user => getUser(user))
            .then(user => { console.log('meio do signup', user); return user })
            .then(user => ApolloClient({ name: 'signup', token: user.tokenEncrypted }).mutate(addDetails(rest)).then(() => Promise.resolve(user)))
    }

    const signout = () => {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                return null
            })
    }

    const getUser = (firebaseUser) => {
        return new Promise((resolve, reject) => {
            emitter.once('authorized', resolve)
            emitter.once('unauthorized', reject)

            const map = (user, tokenResult) => ({
                id: user.uid,
                email: user.email,
                tokenEncrypted: user.Aa,
                token: tokenResult
            })

            const emitIfHasHasura = (firebaseUser, r) => {
                const hasuraClaim = r.claims['https://hasura.io/jwt/claims']

                if (hasuraClaim) {
                    console.debug('possui claim do hasura')
                    emitter.emit('authorized', map(firebaseUser, r))
                }

                return !!hasuraClaim
            }

            firebaseUser?.getIdTokenResult(true)
                .then(result1 => {
                    // se nao tem o token ainda
                    if (!emitIfHasHasura(firebaseUser, result1)) {
                        const metadataRef = firebase.database().ref('metadata/' + firebaseUser.uid + '/refreshTime')

                        // registra listenet de mudancas, a cada mudanca pega o token e tenta resolver
                        metadataRef.on('value', async (data) => {
                            if (!data.exists) return
                            firebaseUser?.getIdTokenResult(true)
                                .then(result2 => emitIfHasHasura(firebaseUser, result2))
                                .catch(e => emitter.emit('unauthorized', e))
                        })
                    }
                })
                .catch(e => emitter.emit('unauthorized', e))
        })
    }

    return {
        signin,
        signup,
        signout,
        getUser,
        sendResetPasswordCode,
        verifyCode,
        confirmNewPassword
    }
}

export default Factory.getInstance()
