import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useStyles from './useStyles'
import { NavLink } from 'react-router-dom'
import { Info } from '@material-ui/icons'

function BoxView({ title, linkpath, linklabel, halfgrid = true, fullgrid, infoTitleIcon, children }) {
    const classes = useStyles()

    const renderLink = () => {
        if (linkpath) {
            return (
                <NavLink to={linkpath} className={classes.head_boxview_link}>
                    <Button className={classes.head_boxview_link} endIcon={<ChevronRightIcon />}>
                        {linklabel}
                    </Button>
                </NavLink>
            )
        }

        return <></>
    }

    const renderHalfGrid = (content) => {
        if (halfgrid) {
            return (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    {content}
                </Grid>
            )
        }

        return <></>
    }

    const renderFullGrid = (content) => {
        if (fullgrid) {
            return (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {content}
                </Grid>
            )
        }

        return <></>
    }

    const renderContent = () => {
        return (
            <div className={classes.container_boxview}>
                <div className={classes.head_boxview}>
                    <div className={classes.head_icon_wrapper}>
                        {infoTitleIcon ? <Info/> : <></> }
                        <Typography className={classes.head_boxview_title}>{title}</Typography>
                    </div>
                    {renderLink()}
                </div>
                {children}
            </div>
        )
    }

    return halfgrid ? renderHalfGrid(renderContent()) : renderFullGrid()
}

export default BoxView
