import React from 'react'
import useStyles from './useStyles'

function LabelContent({ label, children }) {
    const classes = useStyles()

    return (
        <div className={classes.labelcontent_content}>
            <label className={classes.labelcontent_label}>{label}</label>
            <div>{children}</div>
        </div>
    )
}

export default LabelContent
