import { grey } from '@material-ui/core/colors'
const { makeStyles } = require('@material-ui/styles')

const footerLink = {
    textDecoration: 'none',
    color: grey[400],
    fontSize: 10,
    marginLeft: 25,
    marginRight: 25
}

const useStyles = makeStyles({
    footer: {
        marginTop: 'auto'
    },
    divider_footer: {
        background:
            'linear-gradient(to bottom right, transparent calc(50% - 1px), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1) calc(50% + 1px))',
        height: 81,
        zIndex: 10,
        position: 'relative'
    },
    footer_container: {
        padding: '0px 0 10px 0',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        marginTop: -1,
        paddingTop: 1
    },
    footer_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 5
    },
    footer_link: {
        ...footerLink,
        textDecoration: 'none',
        color: grey[400],
        fontSize: 10,
        marginLeft: 25,
        marginRight: 25
    },
    footer_link_menu: {
        ...footerLink,
        marginLeft: 10,
        marginRight: 10
    },
    footer_link_social: {
        marginLeft: 3,
        marginRight: 3
    },
    footer_link_social_icon: {
        width: 26,
        height: 26,
        objectFit: 'cover',
        objectPosition: '50% 50%'
    },
    footer_img: {
        width: 150
    },
    footer_privacy: {
        color: grey[400],
        textAlign: 'center',
        fontSize: 10,
        margin: 0,
        padding: 0
    }
})

export default useStyles
