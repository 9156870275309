import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../auth/useAuth'
import LabelInfo from '../../Molecules/LabelInfo'
import useStyles from './useStyles'

function YourInformation() {
    const { user } = useAuth()
    const { t } = useTranslation()
    const classes = useStyles()
    const transalationKey = 'accountoverview.yourinfo'

    const { claims } = user.token

    return (
        <section key="yourinformation" className={classes.content}>
            <LabelInfo labelKey="name" label={t(`${transalationKey}.name`)} info={claims.name ?? ''} />
            <LabelInfo labelKey="email" label={t(`${transalationKey}.email`)} info={claims.email ?? ''} />
            {claims.displayName && <LabelInfo labelKey="displayname" label={t(`${transalationKey}.displayname`)} info={claims.displayName ?? ''} />}
            {claims.createdAt && <LabelInfo labelKey="membersince" label={t(`${transalationKey}.membersince`)} info={claims.createdAt ?? ''} />}
            {/* <LabelContent key="labelcontent" label={`${t(`${transalationKey}.newsletter`)}:`}>
                {[
                    <span key="newsletter"
                        className={enabled ? classes.newsletterspanenabled : classes.newsletterspandisabled}
                    >
                        {t(`${transalationKey}.enabled`)}
                    </span>,
                    <p key="newsletterp" className={classes.newsletterdescription}>{t(`${transalationKey}.newslettersub`)}</p>
                ]}
            </LabelContent> */}
        </section>
    )
}

export default YourInformation
