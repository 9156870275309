import React from 'react'

import './style.scss'

function BackgroundImg() {
    return (
        <div id="background-img">
            <span className="banner__bg"></span>
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/mercado.png`} alt="Profane" />
            <span className="banner__mask"></span>
        </div>
    )
}

export default BackgroundImg
