import React from 'react'
import { AppBar, Toolbar, Link as MaterialLink, Hidden } from '@material-ui/core'
import useStyles from './useStyles'
import MenuLanguage from '../../Molecules/MenuLanguage'
import MenuUser from '../../Molecules/MenuUser'
import { Link } from 'react-router-dom'
import MenuXsView from '../../Molecules/MenuXsView'

function Header() {
    const classes = useStyles()

    if (!window?.electron) {
        return (
            <AppBar elevation={0} position="static" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>
                    <MaterialLink href="https://www.insa.ne/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo_insane.png`} alt="Insane" />
                    </MaterialLink>
                    <Link to="/accountoverview">
                        <img
                            className={classes.img}
                            src={`${process.env.PUBLIC_URL}/assets/imgs/logo.webp`}
                            alt="Profane"
                        />
                    </Link>
                    <div className={classes.menus}>
                        <Hidden xsDown>
                            <MenuLanguage />
                            <MenuUser />
                        </Hidden>
                        <Hidden smUp>
                            <MenuXsView />
                        </Hidden>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
    return (null)
}

export default Header
