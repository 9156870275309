import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './useStyles'
import SidebarItem from '../SidebarItem'
import menuItens from './menuItens'

function Sidebar() {
    const classes = useStyles()
    const [active, setActive] = useState(-1)
    const { t } = useTranslation()

    useEffect(() => {
        const path = window.location.pathname
        const index = menuItens.findIndex((menuitem) => menuitem.path === path)

        setActive(index)
    }, [])

    const onHandleActive = (active) => {
        setActive(active)
    }

    const renderMenuItens = () =>
        menuItens.map((menuitem, index) => (
            <SidebarItem
                {...menuitem}
                title={t(menuitem.title)}
                key={menuitem.path}
                active={index === active}
                onActive={() => onHandleActive(index)}
            />
        ))

    return (
        <Grid>
            <section key="sidebar" className={classes.menu}>{renderMenuItens()}</section>
        </Grid>
    )
}

export default Sidebar
