import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ResetPasswordCard from '../../Orgs/ResetPasswordCard'
import BackgroundImg from '../../Molecules/BackgroundImg'
import './style.scss'

const ResetPassword = ({ history }) => {
    useTranslation()

    return (
        <Grid container direction="row" justify="center" alignItems="center" id="resetpassword">
            <BackgroundImg />
            <ResetPasswordCard history={history} />
        </Grid>
    )
}

export default ResetPassword
