import { grey } from '@material-ui/core/colors'

const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    btn: {
        color: (props) => (props.active ? grey[800] : grey[500]),
        fontSize: 10,
        justifyContent: 'end',
        padding: 10,
        borderRadius: 0,
        borderBottom: `1px solid ${grey[300]}`,
        backgroundColor: '#fff',
        borderLeftWidth: (props) => (props.active ? 3 : 0),
        borderLeftStyle: 'solid',
        borderLeftColor: '#f4b733',

        '&:hover': {
            color: grey[800],
            backgroundColor: '#fff',
            borderLeftWidth: 3,
            transition: 'border-left-width 0.3s'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'currentColor'
    }
})

export default useStyles
