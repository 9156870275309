import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../auth/useAuth'
import LoadingIcon from '../components/Atoms/LoadingIcon'

const AuthRoute = ({ component: Component, ...rest }) => {
    const { authenticated, loadingAuthState } = useAuth()
    if (loadingAuthState) {
        return (
            <LoadingIcon/>
        )
    }

    return (
        <Route
            {...rest}
            render={props => {
                return authenticated
                    ? <Redirect to={{ pathname: '/accountoverview', state: { prevPath: rest.path } }} />
                    : <Component {...props}/>
            }
            }
        />
    )
}

export default AuthRoute
