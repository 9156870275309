const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    paper: {},
    container: {
        paddingRight: 0,
        paddingLeft: 0
    },
    containerMargin: {
        marginTop: 10
    }
})

export default useStyles
