import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './useStyles'

function CustomButton({ children, ...rest }) {
    const classes = useStyles()

    return (
        <Button className={classes.buttonStyle} {...rest}>
            {children}
        </Button>
    )
}

export default CustomButton
