import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import useStyles from './useStyles'

import './style.scss'

function CardLogin({ children }) {
    const classes = useStyles()

    return (
        <Card className={classes.cardStyle} elevation={1} id="login__card">
            <img src={`${process.env.PUBLIC_URL}/assets/imgs/logo.webp`} alt="Profane" />
            <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
    )
}

export default CardLogin
