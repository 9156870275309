const { makeStyles } = require('@material-ui/styles')

const useStyles = makeStyles({
    labelcontent_content: {
        width: '100%',
        display: 'flex;'
    },
    labelcontent_label: {
        display: 'block',
        width: '30%',
        minWidth: '30%',
        fontWeight: 100,
        fontSize: '0.8rem',
        marginRight: 10
    }
})

export default useStyles
