/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
    LinearProgress,
    Typography,
    Link as MaterialLink
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import CardLogin from '../../Molecules/CardLogin'
import { Trans, useTranslation } from 'react-i18next'
import CustomButton from '../../Atoms/CustomButton'
import { useAuth } from '../../../auth/useAuth'
import useStyles from '../../Molecules/CardLogin/useStyles'
import './style.scss'

function SendPasswordForm(onSubmit, classes, t, step) {
    const validate = (values) => {
        const errors = {}
        const required = 'validation.required'

        if (!values.email) {
            errors.email = t(required)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('validation.email')
        }

        return errors
    }

    return <Formik
        initialValues={{
            email: '',
            password: ''
        }}
        validate={validate}
        onSubmit={onSubmit}
    >
        {({ submitForm, isSubmitting, dirty }) => (
            <Form id="resetpassword__form">
                <Field
                    component={TextField}
                    name="email"
                    type="email"
                    className={classes.input}
                    placeholder={`*${t('login.email')}`} />
                {isSubmitting && <LinearProgress />}
                <CustomButton
                    disabled={isSubmitting || !dirty}
                    onClick={submitForm}
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="large"
                >
                    <Trans i18nKey="login.sendpasswordcode">Send Code</Trans>
                </CustomButton>
            </Form>
        )}
    </Formik>
}

function ResetPasswordCard({ history }) {
    const { sendResetPasswordCode } = useAuth()
    const { t } = useTranslation()
    const classes = useStyles()
    const [step, setStep] = useState(1)

    const setSendPasswordCodeError = (error, setFieldError) => {
        if (error.code === 'auth/invalid-email') {
            setFieldError('email', t('validation.email'))
        }
    }

    const onSendCodeSubmit = async (values, { setSubmitting, setFieldError }) => {
        try {
            await sendResetPasswordCode(values.email)
            setStep(2)
        } catch (error) {
            setSendPasswordCodeError(error, setFieldError)
            console.log('error', error)
        }
        setSubmitting(false)
    }

    console.log('step: ', step)
    return (
        <CardLogin>
            <section key="cardlogin">
                {step === 1 && <Typography className={classes.typography_margintop} variant="subtitle2">
                    <Trans i18nKey="login.resetpassword">Reset Password</Trans>
                </Typography>}
            </section>
            {step === 1 && SendPasswordForm(onSendCodeSubmit, classes, t)}
            {step === 2 && <Typography className={classes.typography_margin} variant="subtitle2">
                <Trans i18nKey="login.resetpasswordverifyemail">Verify your email</Trans>
            </Typography>}
            <div className="signin">
                <Typography className={classes.typography} variant="subtitle2">
                    <Trans i18nKey="login.haveaccount">Have an account</Trans>?
                </Typography>
                <Link className={classes.link} to="/signin">
                    <MaterialLink>
                        <Typography className={classes.typography} variant="subtitle2">
                            <Trans i18nKey="login.signin">Sign in</Trans>
                        </Typography>
                    </MaterialLink>
                </Link>
            </div>
        </CardLogin>
    )
}

export default ResetPasswordCard
