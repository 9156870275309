import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './useStyles'
import env from '../../../env'
import ApolloClient from '../../../apollo/client'
import { gql, useQuery } from '@apollo/client'
import { useAuth } from '../../../auth/useAuth'
import BoxView from '../../Molecules/BoxView'
import { Typography } from '@material-ui/core'

const LINK_DISCORD = gql`
mutation MyMutation($code: String!) {
    linkDiscord(arg1: {code: $code}) {
      data
      success
    }
  }
`

const GET_USER_DISCORD = gql`
query MyQuery {
    user {
      discord_username
    }
  }
`

const linkDiscord = (code) => ({
    mutation: LINK_DISCORD,
    variables: {
        code
    }
})

function DiscordLink() {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [discordError, setDiscordError] = useState()
    const classes = useStyles()
    const transalationKey = 'accountoverview.discordlink'
    // eslint-disable-next-line no-unused-vars
    const { loading, _error, data, refetch } = useQuery(GET_USER_DISCORD)

    useEffect(() => {
        refetch()
    }, [])

    const handleMessage = async (event) => {
        const hasError = event.data.includes('error') && !event.data.includes('access_denied')
        setDiscordError(hasError)
        console.log('hasError: ', hasError)
        if (!hasError) {
            const queryParametersObject = JSON.parse('{"' + decodeURI(event.data).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/^\?/g, '') + '"}')
            await ApolloClient({ name: 'authenticated', token: user?.tokenEncrypted }).mutate(linkDiscord(queryParametersObject.code))
            refetch()
        }
    }

    const handleLogin = async () => {
        try {
            const url = `${env.DISCORD.URL}/oauth2/authorize?client_id=${env.DISCORD.CLIENT_ID}&response_type=code&redirect_uri=${env.DISCORD.CALLBACK_URL}&scope=${env.DISCORD.SCOPE}`
            // window.location.href = url
            window.open(url, 'discordLogin', 'height=700,width=500')
            window.addEventListener('message', handleMessage, false)
        } catch (error) {
            console.error(t(`${transalationKey}.error`), error)
        }
    }

    if (loading) return <></>
    if (data?.user[0]?.discord_username) return <></>

    return (
        <BoxView infoTitleIcon={true} title={t('accountoverview.discordlink.title')}>
            <Typography className={classes.head_boxview_subtitle}>{t('accountoverview.discordlink.subtitle')}</Typography>
            {discordError
                ? <label className={classes.label}>{t(`${transalationKey}.error`)}</label>
                : <></>}
            <div className={classes.wrapper}>
                <section key="discordlink" className={classes.content}>
                    <div onClick={handleLogin} className={classes.discord_btn} >
                        <img src={`${process.env.PUBLIC_URL}/assets/imgs/icon/icons-discord.svg`} alt="Discord" /> <span className={classes.discord_btn_drescription}>SIGN IN</span>
                    </div>
                </section>
            </div>
        </BoxView>
    )
}

export default DiscordLink
