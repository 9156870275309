import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../auth/useAuth'
import Layout from '../components/Templates/Layout'
import LoadingIcon from '../components/Atoms/LoadingIcon'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { authenticated, loadingAuthState } = useAuth()
    if (loadingAuthState) {
        return (
            <LoadingIcon/>
        )
    }

    return (
        <Route
            {...rest}
            render={props => {
                return authenticated
                    ? <Layout {...props}><Component /></Layout>
                    : <Redirect to={{ pathname: '/signin', state: { prevPath: rest.path } }} />
            }
            }
        />
    )
}

export default PrivateRoute
